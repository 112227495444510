<template>
  <v-card flat>
    Empty
  </v-card>
</template>

<script>
export default {
  name: 'Empty'
}
</script>

<style scoped>

</style>
